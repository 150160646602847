<template>
  <div class="logo">
    <router-link
      key="collapse"
      class="logo__link"
      to="/"
    />
  </div>
</template>

<script>
export default {
  name: 'Logo'
}
</script>

<style lang="scss" scoped>
.logo {
  position: relative;
  width: 36px;
  height: 36px;
  line-height: 50px;
  background-color: $navbar-bg-color;
  text-align: center;
  overflow: hidden;
  border-radius: 8px;
  .logo__link {
    display: inline-block;
    width: 100%;
    height: 100%;
    background-image: url('~@/assets/images/logo.png');
    background-size: 100% 100%;
  }
}
</style>
