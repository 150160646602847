/*
 * @Author: Ten
 * @Date: 2021/10/03 10:47
 * @LastEditTime: 2021/10/11 15:21
 * @LastEditors: Ten
 * @Description: Token
 */
import { tokenKey } from '@/settings'
import { getItem, setItem, removeItem } from '@/utils/storage'

export function getToken() {
  return getItem(tokenKey)
}

export function setToken(token) {
  setItem(tokenKey, token)
}

export function removeToken() {
  removeItem(tokenKey)
}
