<template>
  <div class="project">
    <el-dropdown
      trigger="click"
      v-if="options.length"
      @visible-change="isVisible"
      @command="handleCommand"
    >
      <div :class="{'el-dropdown-link': true, 'active': isShow}">
        <div class="left">
          <el-image
            :src="selected.image_path || imgUrl"
            fit="cover"
          />
          <!-- <img :src="selected.image_path ?  selected.image_path : imgUrl" /> -->
          <span class="flex-1 ellipsis-2" :title="selected.title">{{ selected.title }}</span>
        </div>
        <div class="right">
          <i
            :class="{'el-icon-arrow-down': !isShow, 'el-icon-arrow-up': isShow, 'el-icon--right': true}"
          ></i>
        </div>
      </div>
      <el-dropdown-menu slot="dropdown">
        <el-scrollbar>
          <el-dropdown-item v-for="(item, index) in options" :key="index" :command="item" :class="{active: item.id === projectId}">
            <!-- <img :src="item.image_path ? item.image_path : imgUrl" /> -->
            <el-image
              :src="item.image_path || imgUrl"
              fit="cover"
              class="logo"
            />
            <span class="flex-1 ellipsis-2" :title="item.title">{{ item.title }}</span>
          </el-dropdown-item>
        </el-scrollbar>
      </el-dropdown-menu>
    </el-dropdown>
    <div class="empty" v-else>
      <!-- <div class="logo"></div> -->
      <el-image
        :src="imgUrl"
        fit="cover"
        class="logo"
      />
      <p>
        暂无项目
        <template v-if="hasProjectCreateAuth">，去
          <span @click="handleAdd">新增</span>
        </template>
      </p>
    </div>
    <ProjectFormDialog ref="projectFormDialog" @submit="onProjectFormSubmit" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ProjectFormDialog from '@/views/system/project/comps/FormDialog.vue'

export default {
  name: 'ProjectSelector',
  components: {
    ProjectFormDialog
  },
  computed: {
    ...mapGetters({
      options: 'project/data',
      projectId: 'user/projectId'
    }),
    selected() {
      return this.options.find(item => {
        return item.id === this.projectId
      }) || {}
    }
  },
  data() {
    return {
      hasProjectCreateAuth: false,
      imgUrl: './images/default/initlogo.png',
      loading: false,
      isShow: false
    }
  },
  created() {
    this.hasProjectCreateAuth = this.$router.getRoutes().some(route => route.name === 'SystemProject')
  },
  methods: {
    isVisible(val) {
      this.isShow = val
    },
    onProjectFormSubmit() {
      window.location.reload()
    },
    handleAdd() {
      this.$refs.projectFormDialog.open()
    },
    handleCommand(command) {
      localStorage.setItem('projectId', command.id)
      this.$store.dispatch('user/setProjectId', command.id)
      location.reload()
    }
  }
}
</script>

<style lang="scss" scoped>
.el-dropdown {
  width: 100%;
}
.el-dropdown-menu--small {
  border-radius: 4px;
  width: 232px;
  // overflow-y: auto;
  left: 16px !important;
  box-shadow: 0px 8px 20px 0px rgba(0, 14, 26, 0.2);
  ::v-deep .el-scrollbar__wrap {
    max-height: 248px;
  }
  .el-dropdown-menu__item {
    padding: 6px 12px;
    display: flex;
    align-items: center;
    height: 48px;
    line-height: normal;
    font-size: 14px;
    color: $color-text--primary;
    &:hover {
      cursor: pointer;
      background: #f3f3f5;
      color: $color--primary;
    }
    &.active {
      font-weight: 500;
      color: $color--primary;
      background: $menu-bg-color--active;
      &:hover {
        color:#5C9AFC;
      }
    }
  }
}
.el-dropdown-link {
  padding: 6px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  &:hover, &.active {
    cursor: pointer;
    background: #f3f3f5;
    color: $color--primary;
  }
  ::v-deep {
    .el-image {
      width: 36px;
      height: 36px;
      border-radius: 8px;
      overflow: hidden;
      display: block;
      margin-right: 8px;
    }
  }
}
.project {
  padding: 12px 16px;
  border-bottom: 1px solid #EBEDF0;
  .left {
    display: flex;
    align-items: center;
  }
}
.empty {
  padding: 6px 12px;
  display: flex;
  align-items: center;
  color: $breadcrumb-color;
  font-size: 14px;
  font-weight: 400;
  span {
    color: $color--primary;
    cursor: pointer;
  }
  p {
    margin: 0;
  }
}
.logo {
  margin-right: 8px;
  width: 36px;
  height: 36px;
  border-radius: 8px;
}
</style>
