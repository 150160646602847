/*
 * @Author: Ten
 * @Date: 2021/10/11 11:43
 * @LastEditTime: 2021/10/30 13:03
 * @LastEditors: Ten
 * @Description: 路由相关工具方法
 */
import { isFunction } from '@/utils/is'
import { flattenTree, toTree } from '@/utils/tree'
import { trimAll } from '@/utils/string'
import { routerMap } from '@/router/router-map.js'

/**
 * 加载路由拦截器
 * @param {object} interceptors 路由拦截器
 * @param {object} options
 * @return {void}
 * @Author: Ten
 * @Date: 2021/10/11 15:13
 */
export function loadRouteInterceptors(interceptors, options) {
  const { beforeEach, afterEach } = interceptors
  const { router, store, message } = options
  // 路由前置守卫
  beforeEach.forEach(guard => {
    if (isFunction(guard)) {
      router.beforeEach((to, from, next) => guard(to, from, next, {
        router,
        store,
        message
      }))
    }
  })

  // 路由后置守卫
  afterEach.forEach(guard => {
    if (isFunction(guard)) {
      router.afterEach((to, from) => guard(to, from))
    }
  })
}

/**
 * 解析路由组件
 * @param {string} component 组件路径
 * @return {function}
 * @Author: Ten
 * @Date: 2021/10/11 15:13
 */
export function resolveComponent(component) {
  if (component === 'layout') {
    return resolve => require(['@/layout'], resolve)
  } else {
    return resolve => require([`@/views/${component}`], resolve)
  }
}

/**
 * 格式化接口路由数据
 * @param {object} route 路由对象
 * @return {object}
 * @Author: Ten
 * @Date: 2021/10/11 15:14
 */
export function formatRouteData(route) {
  return {
    path: route.path,
    name: route.identify,
    hidden: !route.hidden,
    component: route.component,
    alwaysShow: !route.always_show,
    redirect: route.redirect,
    meta: {
      title: route.title,
      noCache: !route.no_cache,
      icon: route.icon === '/' ? '' : route.icon,
      button: route.buttons
    },
    sorts: route.sorts
  }
}

/**
 * 格式化路由
 * @param {array} routes 路由数据
 * @return {array}
 * @Author: Ten
 * @Date: 2021/10/11 15:14
 */
export function formatRoutes(routes) {
  return routes.map(route => {
    if (route.component) {
      const components = trimAll(route.component).split(',')
      if (components.length > 1) {
        route.component = components[0]
        route.children = [
          {
            ...route,
            identify: route.identify + 'List',
            component: components[1]
          }
        ]
      }
    }
    if (route.children && route.children.length > 0) {
      return {
        ...formatRouteData(route),
        children: formatRoutes(route.children)
      }
    }
    return formatRouteData(route)
  })
}

/**
 * 解析路由
 * @param {array} routes 路由数据
 * @return {array}
 * @Author: Ten
 * @Date: 2021/10/11 15:15
 */
export function loadRoutes(routes) {
  return routes.map(route => {
    if (route.children && route.children.length > 0) {
      return {
        ...route,
        component: resolveComponent(route.component),
        children: loadRoutes(route.children)
      }
    } else {
      return {
        ...route,
        component: resolveComponent(route.component)
      }
    }
  })
}

/**
 * 扁平化路由（处理多层 router-view 无法通过 keep-alive 缓存问题）
 * @param {array} routes 路由数据
 * @return {array}
 * @Author: Ten
 * @Date: 2021/10/11 15:15
 */
export function flattenRoutes(routes) {
  return routes.map(route => {
    if (route.children && route.children.length > 0) {
      return {
        ...route,
        children: flattenTree(route.children).filter(route => !route.children)
      }
    }
    return route
  })
}

/**
 * 初始化路由访问时选中的菜单
 * @param {array} routes 路由数据
 * @param {string} activeMenu 所属菜单组名称
 * @return {array}
 * @Author: Ten
 * @Date: 2021/10/11 15:15
 */
export function initActiveMenuRoutes(routes, activeMenu = '') {
  return routes.map(route => {
    if (route.children && route.children.length > 0) {
      const redirect = route.redirect
      const activeRoute = route.children.find(route => route.path === redirect)
      return {
        ...route,
        children: initActiveMenuRoutes(route.children, activeRoute?.name)
      }
    }
    return {
      ...route,
      meta: {
        ...route.meta,
        activeMenu: route.hidden ? activeMenu : route.name
      }
    }
  })
}

/**
 * 初始化路由redirect参数：处理父级路由没有设置重定向、修正重定向设置错误
 * @param {array} routes 路由数据
 * @return {array}
 * @Author: Ten
 * @Date: 2021/10/11 15:16
 */
export function initRedirectRoutes(routes) {
  // return routes
  // console.log(routes)
  return routes.map(route => {
    if (route.children && route.children.length > 0) {
      if (!route.redirect || !route.children.some(child => child.path === route.redirect)) {
        return {
          ...route,
          redirect: route.children.find(child => !child.hidden)?.path,
          children: initRedirectRoutes(route.children)
        }
      }
      return {
        ...route,
        children: initRedirectRoutes(route.children)
      }
    }
    return route
  })
}

/**
 * 初始化页面标题
 * @param {array} routes 路由数据
 * @param {string} name 路由标识名称
 * @param {string} title 页面标题
 * @return {array}
 * @Author: Ten
 * @Date: 2021/10/11 15:16
 */
export function initPageTitleRoutes(routes, name = '', title = '') {
  return routes.map(route => {
    if (route.children && route.children.length > 0) {
      const redirectRoute = route.children.find(child => child.path === route.redirect)
      if (redirectRoute) {
        title = route.meta?.title || ''
        name = redirectRoute.name
      }
      return {
        ...route,
        children: initPageTitleRoutes(route.children, name, title)
      }
    }
    return {
      ...route,
      meta: {
        ...route.meta,
        title: route.name === name ? title : route.meta?.title
      }
    }
  })
}

/**
 * 初始化首页路由
 * @param {array} routes
 * @return {array}
 * @Author: Ten
 * @Date: 2021/10/19 16:36
 */
export function initHomeRoute(routes) {
  // 没有设置首页，重定向到第一个菜单
  const includeHome = routes.some(item => item.path === '/')
  if (!includeHome) {
    const firstRoute = routes.find(item => item.name)
    let redirect = firstRoute?.redirect || firstRoute?.path
    const flattenRoutes = flattenTree(routes)
    if (!flattenRoutes.some(route => route.path === redirect)) {
      redirect = {
        name: 'Userinfo'
      }
    }
    routes = routes.concat([{
      path: '/',
      redirect
    }])
  }
  return routes
}

/**
 * 初始化错误页路由
 * @param {array} routes
 * @return {array}
 * @Author: Ten
 * @Date: 2021/10/19 16:36
 */
export function initErrorRoute(routes, routesMap = []) {
  if (routesMap.length > 0) {
    // 403
    const routesPathList = flattenTree(routes).map(route => route.path)
    const noAuthRoutesPathList = routesMap.filter(routePath => {
      return !routesPathList.includes(routePath)
    })
    console.log(noAuthRoutesPathList)
    noAuthRoutesPathList.forEach(path => {
      routes.push({
        path,
        redirect: '/403',
        hidden: true
      })
    })
  }
  // 404
  routes.push({
    path: '*',
    redirect: '/404',
    hidden: true
  })
  return routes
}

export function generateAuthRoutes(routes) {
  const flattenRoutes = flattenTree(routes)
  const data = flattenRoutes.map(route => {
    if (route.children && route.children.length > 0) {
      return route
    }
    if (route.buttons === -1) { // 没有任何权限
      return route
    }
    const children = []
    const buttonsMap = route.buttons?.map(button => button.map) || []
    const hasCreateButtonAuth = buttonsMap.some(button => button.includes('create'))
    const hasEditButtonAuth = buttonsMap.some(button => button.includes('edit'))
    const hasSeeButtonAuth = buttonsMap.some(button => button.includes('see'))
    const hasButtonAuth = buttonsMap.length === 0 || hasCreateButtonAuth || hasEditButtonAuth || hasSeeButtonAuth
    if (hasButtonAuth) {
      const create = routerMap[route.component]?.create
      const detail = routerMap[route.component]?.detail
      // 创建新增/编辑页
      if (create && (buttonsMap.length === 0 || hasCreateButtonAuth || hasEditButtonAuth)) {
        const path = route.path + '/:id'
        children.push({
          pid: route.id,
          title: create.title,
          identify: create.name,
          path,
          component: create.component,
          hidden: 0,
          no_cache: 1,
          always_show: 1,
          redirect: null,
          buttons: route.buttons,
          children: []
        })
      }
      // 创建详情页
      if (detail && (buttonsMap.length === 0 || hasSeeButtonAuth)) {
        const path = route.path + '/info/:id'
        children.push({
          pid: route.id,
          title: detail.title,
          identify: detail.name,
          path,
          component: detail.component,
          hidden: 0,
          no_cache: 1,
          always_show: 1,
          redirect: null,
          buttons: route.buttons,
          children: []
        })
      }
    }
    // 如果有新增/详情页，则创建列表页
    if (children.length > 0) {
      children.unshift({
        pid: route.id,
        title: route.title,
        identify: route.identify,
        path: route.path,
        component: route.component,
        hidden: 1,
        no_cache: 1,
        always_show: 1,
        redirect: null,
        buttons: route.buttons,
        children: []
      })
    }
    return {
      ...route,
      component: children.length > 0 ? null : route.component,
      children
    }
  })
  return toTree(data)
}
