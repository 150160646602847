/*
 * @Author: Ten
 * @Date: 2021/10/03 18:44
 * @LastEditTime: 2021/10/20 17:12
 * @LastEditors: Qy
 * @Description: 请求/响应拦截器
 */
import { appId } from '@client/settings.js'

const isBlob = function(data) {
  return data instanceof Blob
}

// 忽略 project 参数的API
const ignoreProjectAPI = [
  /\/captcha$/, // 验证码
  /\/login$/, // 登录
  /\/wechat-login$/, // 企微扫码登录
  /\/user-info$/ // 用户权限
//   /\/project(?:s|-secret\/|\/\d|)$/, // 项目
//   /\/menu(?:s|s-button|-status\/\d|-secret\/\d|\/\d|)$/, // 菜单
//   // /\/role(?:s|-status\/\d|\/\d|)$/, // 角色
//   /\/user(?:s|\/\d|)$/, // 用户
//   /\/department(?:s|\/\d|)$/, // 部门
//   /\/config(?:s|-tree|-key-value|-group|-group-list|-status\/\d|\/\d|)$/, // 配置
//   /\/api-url(?:s|\/\d|)$/, // 接口权限
//   /\/button(?:\/\d|)$/, // 按钮权限
//   /\/log(?:|-export)$/ // 日志
]

// 忽略 project 参数的访问地址
const ignoreProjectURL = [
  /^#\/system/
]

// 强制传递 project 参数的接口地址
const enforceProjectAPI = [
  /\/menu/
]

// 公共请求拦截
const commonRequstInterceptor = {
  onResolved(config, options) {
    const { store } = options
    // appid
    config.headers['access-appid'] = appId
    // token
    config.headers['access-token'] = store.getters['user/token'] || ''
    return config
  },
  onRejected(error) {
    console.error(error)
    return Promise.reject(error)
  }
}

// 公共响应拦截
const commonResponseInterceptor = {
  onRejected(error, options) {
    console.log(error)
    const { message } = options
    message.error(error.message)
    return Promise.reject(error)
  }
}

// 状态码响应拦截
const statusResponseInterceptor = {
  onResolved(response, options) {
    if (
      !response.config?.customResponse && // 自定义回调处理
      typeof response.data === 'object' &&
      !isBlob(response.data) // 文件下载
    ) {
      const { store, message } = options
      const res = response.data
      if (res.status === 0) {
        return res
      } else {
        if (res.code === 11102 || res.code === 11103 || res.code === 11203) { // token 过期
          store.dispatch('user/logout')
        }
        message.error(res.msg)
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({
          status: res.status,
          code: res.code,
          msg: res.msg
        })
      }
    }
    return response
  }
}

// 过滤GET请求中空字符串的参数
const filterEmptyGetParamsInterceptor = {
  onResolved(config) {
    if (config.method === 'get') {
      if (Object.keys(config.params || {}).length > 0) {
        config.params = Object.keys(config.params)
          .filter(key => config.params[key] !== '')
          .reduce((obj, key) => {
            obj[key] = config.params[key]
            return obj
          }, {})
      }
    }
    return config
  }
}

// 设置全局 project 参数
const projectParamsInterceptor = {
  onResolved(config, options) {
    if (
      !ignoreProjectAPI.some(api => api.test(config.url)) &&
      (
        !ignoreProjectURL.some(url => url.test(location.hash)) ||
        enforceProjectAPI.some(api => api.test(config.url))
      )
    ) {
      if (config.method === 'get') {
        config.params = {
          ...config.params
        }
      } else if (config.method === 'post' || config.method === 'put') {
        config.data = {
          ...config.data
        }
      } else if (config.method === 'delete') {
        config.data = {
          ...config.data
        }
      }
    }
    return config
  }
}

// 请求拦截器
export const requestInterceptors = [
  commonRequstInterceptor,
  filterEmptyGetParamsInterceptor,
  projectParamsInterceptor
]

// 响应拦截器
export const responseInterceptors = [
  commonResponseInterceptor,
  statusResponseInterceptor
]
