/*
 * @Author: Ten
 * @Date: 2021/08/09 10:38
 * @LastEditTime: 2021/10/13 17:00
 * @LastEditors: Ten
 * @Description: 树结构相关函数
 */
/**
 * 递归格式化成多维数组
 * @param {array} array 一维数组
 * @param {number} root 根id
 * @param {string} pk 当前id标识
 * @param {string} pid 父级id标识
 * @param {string} child 层级属性名称
 * @param {object} other 其他附加属性
 * @return {array}
 * @Author: Ten
 * @Date: 2021/09/10 16:47
 */
export function toTree(array, root = 0, pk = 'id', pid = 'pid', property = 'children', other = null) {
  const tree = []
  if (array) {
    array.forEach(item => {
      if (item[pid] === root) {
        if (other !== null) {
          item = Object.assign({}, item, other)
        }
        const children = toTree(array, item[pk], pk, pid, property, other)
        if (children.length) {
          item[property] = children
        }
        tree.push(item)
      }
    })
  }
  return tree
}

/**
 * 扁平化对象数组
 * @param {array} array 多维数组
 * @param {*} property 层级属性名称
 * @return {array}
 * @Author: Ten
 * @Date: 2021/09/10 16:47
 */
export function flattenTree(array = [], property = 'children') {
  if (array.length === 0) {
    return []
  }
  const flattenArray = []
  function loop(array) {
    array.forEach(item => {
      flattenArray.push(item)
      if (item[property] && item[property].length > 0) {
        loop(item[property])
      }
    })
  }
  loop(array)
  return flattenArray
}

/**
 * 获取指定id的元素
 * @param {number} id 元素id
 * @param {array} array 数组
 * @param {string} property 层级属性名称
 * @return {object}
 * @Author: Ten
 * @Date: 2021/09/10 16:47
 */
export function getObjectById(id, array, property = 'children') {
  if (array.length === 0) {
    return {}
  }
  let object = {}
  function loop(array) {
    array.forEach(item => {
      if (item.id === id) {
        object = item
      }
      if (item[property] && item[property].length > 0) {
        return loop(item[property])
      }
    })
    return object
  }
  return loop(array)
}

/**
 * 筛选树结构
 * @param {array} array 树形结构数组
 * @param {string} keyword 关键字
 * @param {object} props 配置选项
 * @return {array}
 * @Author: Ten
 * @Date: 2021/09/22 19:57
 */
export function filterTree(array, keyword, props = { label: 'label', value: 'value' }) {
  const flattenData = flattenTree(array)
  const filterData = flattenData.filter(item => {
    return item[props.label].includes(keyword)
  })
  const pidList = filterData.reduce((result, current) => {
    return result.concat(getParentsIdListById(flattenData, current[props.value]))
  }, [])
  const cidList = filterData.reduce((result, current) => {
    return result.concat(getChildrenIdListById(flattenData, current[props.value]))
  }, [])
  const idList = filterData.map(item => item[props.value])
  return flattenData.filter(item => {
    return pidList.includes(item[props.value]) || idList.includes(item[props.value]) || cidList.includes(item[props.value])
  })
}

/**
 * 获取所有父级id
 * @param {array} array 数组
 * @return {array}
 * @Author: Ten
 * @Date: 2021/09/10 16:47
 */
export function getParentsIdList(array = []) {
  if (array.length === 0) {
    return []
  }
  const parentsIdList = []
  function loop(array) {
    array.forEach(item => {
      if (item.children && item.children.length > 0) {
        parentsIdList.push(item.id)
        return loop(item.children)
      }
    })
    return parentsIdList
  }
  return loop(array)
}

/**
 * 获取指定id的所有父级id
 * @param {array} array
 * @param {number|string} id
 * @param {number|string} endId
 * @return {array}
 * @Author: Ten
 * @Date: 2021/09/10 16:47
 */
export function getParentsIdListById(array = [], id, endId = 0) {
  if (array.length === 0) {
    return []
  }
  const ids = []
  const loop = function(array = [], id) {
    for (const item of array) {
      if (item.id === id) {
        ids.push(item.pid)
        break
      }
    }
    if (ids.length > 0) {
      const lastId = ids[ids.length - 1]
      if (lastId === endId) {
        return ids.filter(id => id !== endId).reverse()
      }
      return loop(array, lastId)
    }
    return ids
  }
  return loop(array, id)
}

/**
 * 获取指定id的所有子级id
 * @param {array} array
 * @param {number|string} id
 * @param {string} pid 父级id标识
 * @param {string} property 层级属性名称
 * @return {array}
 * @Author: Ten
 * @Date: 2021/09/22 19:46
 */
export function getChildrenIdListById(array = [], id, pid = 'pid', property = 'children') {
  if (array.length === 0) {
    return []
  }
  const ids = []
  const loop = function(array = [], id, pid, property) {
    array.forEach(item => {
      if (item[pid] === id) {
        ids.push(item.id)
        if (item[property] && item[property].length > 0) {
          return loop(item[property], item.id, pid, property)
        }
      }
    })
    return ids
  }
  return loop(array, id, pid, property)
}

/**
 * 获取指定id的所有子级
 * @param {array} array
 * @param {number|string} id
 * @param {string} pid 父级id标识
 * @param {string} property 层级属性名称
 * @return {array}
 * @Author: Ten
 * @Date: 2021/09/22 19:46
 */
export function getChildrenListById(array = [], id, pid = 'pid', property = 'children') {
  if (array.length === 0) {
    return []
  }
  const list = []
  const loop = function(array = [], id, pid, property) {
    array.forEach(item => {
      if (item[pid] === id) {
        list.push(item)
        if (item[property] && item[property].length > 0) {
          return loop(item[property], item.id, pid, property)
        }
      }
    })
    return list
  }
  return loop(array, id, pid, property)
}

/**
 * 获取最低级id
 * @param {array} array
 * @param {sting} property
 * @return {array}
 * @Author: Ten
 * @Date: 2021/10/11 16:29
 */
export function getLowerIdList(array = [], property = 'children') {
  if (array.length === 0) {
    return []
  }
  const lowerIdList = []
  function loop(array) {
    array.forEach(item => {
      if (!item[property] || item[property].length === 0) {
        lowerIdList.push(item.id)
        return loop(item[property])
      }
    })
    return lowerIdList
  }
  return loop(array)
}

/**
 * 查找最底级的元素
 * @param {array} array
 * @param {number} id
 * @param {string} pid
 * @param {sting} property
 * @return {array}
 * @Author: Ten
 * @Date: 2021/10/11 16:30
 */
export function getLowerChildrens(array, id, pid = 'pid', property = 'children') {
  return flattenTree(array)
    .filter(item => item[property] && item[property].length > 0 && (item[pid] === id || item.id === id))
}
