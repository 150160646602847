<!--
 * @Author: Ten
 * @Date: 2022/02/28 11:51
 * @LastEditTime: 2022/03/01 14:30
 * @LastEditors: Ten
 * @Description: 添加/编辑项目
-->
<template>
  <el-dialog
    :visible.sync="dialogFormVisible"
    :close-on-click-modal="false"
    :append-to-body="true"
    :title="currentTitle"
    width="450px"
    @close="onCloseDialog"
  >
    <el-form
      ref="form"
      v-loading="loading"
      :rules="rules"
      :model="form"
      label-width="95px"
      class="is-dialog"
    >
      <el-form-item label="项目名称：" prop="title">
        <el-input
          v-model.trim="form.title"
          maxlength="10"
          clearable
        />
      </el-form-item>
      <el-form-item label="AppKey：" prop="key">
        <el-input
          v-model="form.key"
          maxlength="50"
          clearable
        />
      </el-form-item>
      <el-form-item label="备注：" prop="desc">
        <el-input
          v-model.trim="form.desc"
          type="textarea"
          maxlength="200"
          clearable
        />
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      v-loading="loading"
      class="dialog-footer"
      element-loading-spinner=" "
    >
      <el-button @click="dialogFormVisible = false">取 消</el-button>
      <el-button
        :loading="loadingSubmit"
        :disabled="loading"
        type="primary"
        @click="handleSubmit"
      >
        {{
          loadingSubmit ? '保存中' : this.projectId === 0 ? '确 定' : '保 存'
        }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import {
  createProject,
  readProject,
  updateProject
} from '@/api/base/modules/project'

export default {
  name: 'FormDialog',
  data() {
    return {
      dialogFormVisible: false,
      loading: false,
      loadingSubmit: false,
      projectId: 0,
      form: {
        title: '',
        key: '',
        desc: ''
      },
      rules: {
        title: [{ required: true, message: '请输入项目名称', trigger: 'blur' }],
        key: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入AppKey'
          }
        ]
      }
    }
  },
  computed: {
    currentTitle() {
      return this.projectId === 0 ? '添加项目' : '编辑'
    },
    currentSuccessTips() {
      return this.projectId === 0 ? '添加成功' : '保存成功'
    }
    // projects() {
    //   return this.$store.getters['project/data']
    // }
  },
  methods: {
    open(id) {
      this.projectId = id || 0
      if (this.projectId) {
        this.loading = true
        readProject(id).then((res) => {
          this.loading = false
          this.form.title = res.data.title
          this.form.key = res.data.key
          this.form.desc = res.data.desc
        }).catch(err => {
          console.log(err)
          this.loading = false
        })
      }
      this.dialogFormVisible = true
    },
    onCloseDialog() {
      this.projectId = 0
      this.loading = false
      this.loadingSubmit = false
      this.$refs.form.resetFields()
    },
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.savePlatform()
        }
      })
    },
    getParams() {
      return {
        title: this.form.title,
        key: this.form.key,
        desc: this.form.desc || undefined
      }
    },
    async savePlatform() {
      try {
        this.loadingSubmit = true
        if (this.projectId) {
          await updateProject(this.projectId, this.getParams())
        } else {
          await createProject(this.getParams())
        }
        this.$emit('submit', this.projectId)
        this.dialogFormVisible = false
        this.loadingSubmit = false
        this.$message.success(this.currentSuccessTips)
      } catch (err) {
        console.log(err)
        this.loadingSubmit = false
      }
    }
  }
}
</script>
