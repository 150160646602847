<template>
  <div class="navbar">
    <div class="navbar__item" :class="{ 'navbar__item--collapse': isCollapse }">
      <Logo v-if="showLogo" />
      <div v-show="!isCollapse" class="navbar__name">{{ appName }}</div>
    </div>

    <div class="navbar__item">
      <!-- <template v-if="showSizeSelect && device !== 'mobile'">
        <el-tooltip content="全局尺寸" effect="dark" placement="bottom">
          <SizeSelect class="navbar__inner" />
        </el-tooltip>
      </template> -->

      <div class="avatar-container navbar__inner">
        <div class="avatar-wrapper">
          <!-- <el-avatar class="user-avatar" :size="40" :src="avatar"></el-avatar> -->
          <span class="user-avatar">{{ avatarName }}</span>
          <span>{{ name }}</span>
          <span class="user-logout" @click="logout">
            <svg-icon
              icon-class="quit"
              class-name="user-logout__icon"
            ></svg-icon>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Logo from '@/layout/comps/Logo/index.vue'
import { appName } from '@/settings'
import { cutStrByChar } from '@/utils/string'

export default {
  name: 'Navbar',
  components: {
    Logo
  },
  data() {
    return {
      appName
    }
  },
  computed: {
    ...mapState({
      showLogo: (state) => state.settings.showLogo,
      showSizeSelect: (state) => state.settings.showSizeSelect
    }),
    ...mapGetters({
      aside: 'app/aside',
      name: 'user/realname',
      device: 'app/device',
      avatar: 'user/avatar'
    }),
    isCollapse({ aside }) {
      return !aside.opened
    },
    avatarName() {
      return cutStrByChar(this.name, 2)
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('user/logout', 'login')
    },
    openPath(name) {
      if (this.$route.name === name) {
        return
      }
      this.$router.push({
        name
      })
    },
    handleOpenPath(name) {
      if (name === 'logout') {
        this.logout()
        return
      }
      this.openPath(name)
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  display: flex;
  justify-content: space-between;
  position: relative;
  height: $navbar-height;
  background-color: $navbar-bg-color;
  color: $navbar-text-color;
  overflow: hidden;
  .navbar__item {
    display: flex;
    align-items: center;
    height: 100%;
    line-height: 50px;
    &:focus {
      outline: none;
    }
    &:first-child {
      display: flex;
      justify-content: center;
      // width: $aside-width;
      padding-left: 24px;
    }
    &.navbar__item--collapse {
      width: $aside-width--mini;
      padding-left: 0;
    }
    .navbar__inner {
      display: flex;
      padding-left: 24px;
      padding-right: 24px;
      font-size: 16px;
      color: $navbar-text-color;
      vertical-align: text-bottom;
      transition: background-color 0.3s;
      &:hover {
        cursor: pointer;
      }
    }

    .navbar__name {
      margin-left: 8px;
      font-size: 18px;
      font-weight: 600;
      color: $navbar-text-color;
    }
  }

  .avatar-container {
    .avatar-wrapper {
      display: flex;
      align-items: center;
      position: relative;
      color: $navbar-text-color;

      .user-avatar {
        margin-right: 8px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        background-color: $color--primary;
        border-radius: 50%;
        text-align: center;
        font-size: 18px;
        color: $color--white;
      }
      .user-logout {
        display: flex;
        margin-left: 16px;
        width: 16px;
        height: 16px;
        .user-logout__icon {
          font-size: 16px;
          color: #fff;
        }
      }

      .el-icon-switch-button {
        width: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 18px;
        color: $navbar-text-color;
      }
    }
  }
}
</style>
