/*
 * @Author: Ten
 * @Date: 2021/10/10 18:38
 * @LastEditTime: 2021/11/01 16:07
 * @LastEditors: Ten
 * @Description: 请求方法
 */
import axios from 'axios'
import { requestInterceptors, responseInterceptors } from './interceptors'
import { loadRequestInterceptors as _loadRequestInterceptors } from '@/utils/request'

const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 50000
})

/**
 * 请求拦截器
 * @param {object} options
 * @return {void}
 * @Author: Ten
 * @Date: 2021/10/11 12:03
 */
export function loadRequestInterceptors(options) {
  _loadRequestInterceptors(request, {
    requestInterceptors,
    responseInterceptors
  }, options)
}

export default request
