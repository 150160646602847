<template>
  <div class="logo">
    <router-link key="expand" class="logo__link" to="/" />
  </div>
</template>

<script>
export default {
  name: 'Logo'
}
</script>

<style lang="scss" scoped>
.logo {
  position: relative;
  width: 200px;
  height: 60px;
  line-height: 60px;
  overflow: hidden;
  margin-left: 50px;
  .logo__link {
    display: inline-block;
    width: 100%;
    height: 100%;
    background-image: url('~@client/assets/images/logo.png');
    background-size: cover;
    background-repeat: no-repeat;
  }
}
</style>
