const state = {
  device: 'desktop',
  size: localStorage.getItem('size') || 'mini'
}

const getters = {
  size: ({ size }) => size,
  device: ({ device }) => device
}

const mutations = {
  toggleDevice: (state, device) => {
    state.device = device
  },
  setSize: (state, size) => {
    state.size = size
  }
}

const actions = {
  toggleDevice({ commit }, device) {
    commit('toggleDevice', device)
  },
  setSize({ commit }, size) {
    localStorage.setItem('size', size)
    commit('setSize', size)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
