/*
 * @Author: Ten
 * @Date: 2021/10/21 10:17
 * @LastEditTime: 2021/10/21 11:19
 * @LastEditors: Ten
 * @Description:
 */
/**
 * 是否为Blob对象
 * @param {blob} value
 * @return {boolean}
 * @Author: Ten
 * @Date: 2021/10/21 11:17
 */
export function isBlob(value) {
  return value instanceof Blob
}

/**
 * 是否为字符串类型
 * @param {string} value
 * @return {boolean}
 * @Author: Ten
 * @Date: 2021/10/11 17:01
 */
export function isString(value) {
  if (typeof value === 'string' || value instanceof String) {
    return true
  }
  return false
}

/**
 * 是否为数组类型
 * @param {array} value
 * @return {boolean}
 * @Author: Ten
 * @Date: 2021/10/11 17:01
 */
export function isArray(value) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(value) === '[object Array]'
  }
  return Array.isArray(value)
}

/**
 * 是否函数
 * @param {function} fn
 * @return {boolean}
 * @Author: Ten
 * @Date: 2021/07/03 17:08
 */
export function isFunction(fn) {
  return typeof fn === 'function'
}
