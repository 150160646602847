import dayjs from 'dayjs'
import store from '@/store'

export function filterFormatDateOfTable(value) {
  if (value) {
    return dayjs(value).format('YYYY-MM-DD HH:mm')
  }
  return '/'
}

export function filterFormatDate(value) {
  return dayjs(value).format('YYYY-MM-DD')
}

export function filterForamtSerialNumber(index, page, psize = 20) {
  index = ((page - 1) * psize + index + 1)
  return index < 10 ? `0${index}` : index
}

export function filterFormatTimeOfTable(second) {
  return second ? `${Math.floor(second / 60)}分${second % 60}秒` : ''
}

/**
 * 按钮类型过滤器
 * @param {string} map
 * @return {string}
 * @Author: Ten
 * @Date: 2021/10/13 15:00
 */
export function filterButtonMapTitle(map) {
  const buttonMap = store.getters['permissions/buttonMap']
  return buttonMap.find(button => button.map === map)?.label || ''
}

/**
 * 接口权限配置所属类型过滤器
 * @param {number} type
 * @return {string}
 * @Author: Ten
 * @Date: 2021/10/13 15:00
 */
export function filterURLTypeTitle(type) {
  const buttonMap = store.getters['permissions/buttonMap']
  return buttonMap.find(button => button.value === type)?.label || ''
}

// 数字金额过滤器，保留两位，显示千分位符号
export function filterFormatMoney(value) {
  if (!value) return '0.00'
  // 将数值截取，保留两位小数
  value = value.toFixed(2)
  // 获取整数部分
  const intPart = Math.trunc(value)
  // 整数部分处理，增加,
  const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
  // 预定义小数部分
  let floatPart = '.00'
  // 将数值截取为小数部分和整数部分
  const valueArray = value.toString().split('.')
  if (valueArray.length === 2) { // 有小数部分
    floatPart = valueArray[1].toString() // 取得小数部分
    return intPartFormat + '.' + floatPart
  }
  return intPartFormat + floatPart
}
