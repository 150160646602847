import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Element from './element-ui'
import store from './store'
import permissions from './permissions'

import '@/styles/index.scss'
import '@/styles/iconfont/iconfont.css'

import '@/icons'
import * as filters from '@/filters'
import { DatetimePicker, Popup, Picker } from 'vant'
Vue.use(DatetimePicker)
Vue.use(Picker)
Vue.use(Popup)
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
Vue.use(Element, {
  size: 'small',
  zIndex: 4000
})

Vue.config.productionTip = false

permissions({
  router,
  store,
  message: Vue.prototype.$message
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
