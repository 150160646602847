<!--
 * @Author: Qy
 * @Date: 2021/10/15 15:32
 * @LastEditTime: 2021/10/16 13:43
 * @LastEditors: Qy
 * @Description:
-->
<template>
  <section class="main">
    <keep-alive >
      <router-view :key="key" />
    </keep-alive>
  </section>
</template>

<script>
export default {
  name: 'Main',
  computed: {
    key() {
      return this.$route.path
    }
  }
}
</script>
<style lang="scss" scoped>
.main {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  box-sizing: content-box;
  overflow: hidden;
  background-color: #F3F5F7;
}
</style>
