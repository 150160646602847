/*
 * @Author: Ten
 * @Date: 2021/08/09 10:38
 * @LastEditTime: 2021/11/10 17:05
 * @LastEditors: Qy
 * @Description: 验证方法
 */
/**
 * 校验用户名
 * 字母，数字，下划线6-18位
 * @param {string} value
 * @return {boolean}
 * @Author: Ten
 * @Date: 2021/10/11 17:01
 */
export function validUsername(value) {
  const reg = /^[a-zA-Z_0-9]{6,18}$/
  return reg.test(value)
}

/**
 * 校验密码
 * 大小写字母、数字中的两种，6-18位
 * @param {string} value
 * @return {boolean}
 * @Author: Ten
 * @Date: 2021/10/11 17:01
 */
export function validPassword(value) {
  const reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[a-zA-Z0-9].{5,18}$/
  return reg.test(value)
}

/**
 * 校验验证码
 * 字母，数字，4位
 * @param {string} value
 * @return {boolean}
 * @Author: Ten
 * @Date: 2021/10/11 17:01
 */
export function validCaptcha(value) {
  const reg = /^[a-zA-Z_0-9]{4}$/
  return reg.test(value)
}

/**
 * 是否为http链接格式
 * @param {string} value
 * @return {boolean}
 * @Author: Ten
 * @Date: 2021/10/11 17:01
 */
export function validURL(value) {
  const reg = /(^#)|(^http(s*):\/\/[^\s]+\.[^\s]+)/
  return reg.test(value)
}

/**
 * 是否为ip格式
 * @param {string} value
 * @return {boolean}
 * @Author: Ten
 * @Date: 2021/10/11 17:01
 */
export function validIP(value) {
  const reg = /(^#)|(^http(s*)):\/\/((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})(\.((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})){3}/g
  return reg.test(value)
}

/**
 * 是否为小写字母
 * @param {string} value
 * @return {boolean}
 * @Author: Ten
 * @Date: 2021/10/11 17:01
 */
export function validLowerAlphabets(value) {
  const reg = /^[a-z]+$/
  return reg.test(value)
}

/**
 * 是否为大写字母
 * @param {string} value
 * @return {boolean}
 * @Author: Ten
 * @Date: 2021/10/11 17:01
 */
export function validUpperAlphabets(value) {
  const reg = /^[A-Z]+$/
  return reg.test(value)
}

/**
 * 是否包含大小写字母
 * @param {string} value
 * @return {boolean}
 * @Author: Ten
 * @Date: 2021/10/11 17:01
 */
export function validAlphabets(value) {
  const reg = /^[A-Za-z]+$/
  return reg.test(value)
}

/**
 * 是否为邮箱格式
 * @param {string} value
 * @return {boolean}
 * @Author: Ten
 * @Date: 2021/10/11 17:01
 */
export function validEmail(value) {
  // eslint-disable-next-line no-useless-escape
  const reg = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
  return reg.test(value)
}

/**
 * 是否为手机号码格式
 * @param {string} value
 * @return {boolean}
 * @Author: Ten
 * @Date: 2021/10/11 17:01
 */
export function validPhone(value) {
  const reg = /^1\d{10}$/
  return reg.test(value)
}

/**
 * 是否为身份证号码格式
 * @param {string} value
 * @return {boolean}
 * @Author: Ten
 * @Date: 2021/10/11 17:01
 */
export function validIdentity(value) {
  const reg = /(^\d{15}$)|(^\d{17}(x|X|\d)$)/
  return reg.test(value)
}

/**
 * 是否为外部链接
 * @param {string} value
 * @return {boolean}
 * @Author: Ten
 * @Date: 2021/10/11 17:01
 */
export function isExternal(value) {
  return /^(https?:|mailto:|tel:)/.test(value)
}
/**
 * 表单校验，页面直接滚动定位在有错误的地方
 * @param {*} targetEl 选择报错的元素
 * @param {*} posTop 减去顶部的位置
 * @param {*} cb 滚动到错误位置后执行的函数
 * @return {*}
 * @Author: Qy
 * @Date: 2021/11/10 16:16
 */
export function validToErrorPoint(targetEl, posTop = 0, cb) {
  if (document.querySelector(targetEl) === null) {
    return false
  }
  const errorTracingPoint =
    document.querySelector(targetEl).getBoundingClientRect().top +
    document.scrollingElement.scrollTop

  document.scrollingElement.scrollTop = errorTracingPoint - posTop
  cb()
}
