/*
 * @Author: Ten
 * @Date: 2021/10/18 17:06
 * @LastEditTime: 2021/10/20 20:15
 * @LastEditors: Ten
 * @Description: 权限配置
 */
import request from '@/api/base/request'

export function readConfigPermission() { // 权限配置列表接口
  return request({
    url: '/config-permission',
    method: 'get'
  })
}
