/*
 * @Author: Ten
 * @Date: 2021/06/21 07:29
 * @LastEditTime: 2021/10/20 20:40
 * @LastEditors: Qy
 * @Description: 路由拦截器
 */
import NProgress from 'nprogress'
// import { isMobile } from '@client/utils/browser'
// import router from '@client/router'

NProgress.configure({
  showSpinner: false
})

// 加载进度条开始拦截器
const progressStart = (to, from, next) => {
  if (!NProgress.isStarted()) {
    NProgress.start()
  }
  next()
}

// 移动端拦截器
// const mobileInterceptor = async (to, from, next, options) => {
//   console.log('mobileInterceptor', /(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent))
//   if (/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) {
//   //   console.log('mobile')
//     // await store.dispatch('app/JumpToMobile')
//     // router.replace({ path: '/mobile' })
//     router.push({ path: '/mobile' })
//     NProgress.done()
//   } else {
//     router.push({ path: '/' })
//   }
// }

// 加载进度条结束拦截器
const progressDone = () => {
  NProgress.done()
}

// 路由加载前拦截器
export const beforeEachInterceptors = [
  progressStart
]

// 路由加载后拦截器
export const afterEachInterceptors = [
  progressDone
]
