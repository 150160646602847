/*
 * @Author: Ten
 * @Date: 2021/08/09 10:18
 * @LastEditTime: 2021/11/01 16:06
 * @LastEditors: Ten
 * @Description: 权限配置相关
 */
import { beforeEachInterceptors, afterEachInterceptors } from '@client/router/interceptors'
import { loadRequestInterceptors } from '@client/api/base/request'
import { loadRouteInterceptors } from '@/utils/router'

function permissions({ router, store, message }) {
  // 加载路由拦截器
  loadRouteInterceptors(
    {
      beforeEach: beforeEachInterceptors,
      afterEach: afterEachInterceptors
    },
    {
      router,
      store,
      message
    }
  )
  // 加载请求拦截器
  loadRequestInterceptors({
    store,
    message
  })
}

export default permissions
