/*
 * @Author: Ten
 * @Date: 2021/10/03 18:44
 * @LastEditTime: 2021/12/17 12:11
 * @LastEditors: Ten
 * @Description: 请求/响应拦截器
 */
import { appId, isJoinUUMS } from '@/settings.js'
import { isBlob } from '@/utils/is'

// 公共请求拦截
export const commonRequstInterceptor = {
  onResolved(config, options) {
    const { store } = options
    // appid
    config.headers['access-appid'] = appId
    // token
    config.headers['access-token'] = store.getters['user/token'] || ''
    return config
  },
  onRejected(error) {
    console.error(error)
    return Promise.reject(error)
  }
}

// 公共响应拦截
export const commonResponseInterceptor = {
  onRejected(error, options, instance) {
    console.log(error)
    if (!instance?.isCancel || !instance?.isCancel(error)) {
      const { message } = options
      message.error(error.message)
    }
    return Promise.reject(error)
  }
}

// 状态码响应拦截
export const statusResponseInterceptor = {
  onResolved(response, options) {
    if (
      !response.config?.customResponse && // 自定义回调处理
      typeof response.data === 'object' &&
      !isBlob(response.data) // 文件下载
    ) {
      const { store, message } = options
      const res = response.data
      if (res.status === 0) {
        return res
      } else {
        if (res.code === 11001) { // token缺失 || token过期 || 用户被禁用 || 用户不存在
          message.error(res.msg)
          setTimeout(() => {
            store.dispatch('user/logout', 'login')
          }, isJoinUUMS ? 1000 : 0)
        } else {
          message.warning(res.msg)
        }
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({
          status: res.status,
          code: res.code,
          msg: res.msg
        })
      }
    }
    return response
  }
}

// 过滤GET请求中空字符串的参数
export const filterEmptyGetParamsInterceptor = {
  onResolved(config) {
    if (config.method === 'get') {
      if (Object.keys(config.params || {}).length > 0) {
        config.params = Object.keys(config.params)
          .filter(key => config.params[key] !== '')
          .reduce((obj, key) => {
            // 【ID1041551】【所有模糊搜索框】可输入空格，搜索时自动过滤左右空格
            const val = config.params[key]
            typeof (val) === 'string' ? (obj[key] = val.replace(/(^\s*)|(\s*$)/g, '')) : (obj[key] = val)
            return obj
          }, {})
      }
    }
    return config
  }
}

// 请求拦截器
export const requestInterceptors = [
  commonRequstInterceptor,
  filterEmptyGetParamsInterceptor
]

// 响应拦截器
export const responseInterceptors = [
  commonResponseInterceptor,
  statusResponseInterceptor
]
