import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/index.vue'
import { isJoinUUMS } from '@/settings'

Vue.use(VueRouter)

export const routes = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path*',
        component: () => import('@/views/redirect/index')
      }
    ]
  },
  {
    path: '/userinfo',
    component: Layout,
    hidden: false,
    alwaysShow: false,
    redirect: '/userinfo',
    meta: {},
    props: {
      showAside: true
    },
    children: [
      {
        path: '/userinfo',
        component: () => import('@/views/userinfo/index'),
        name: 'Userinfo',
        hidden: false,
        alwaysShow: false,
        meta: {
          title: '个人信息',
          icon: 'idcard',
          noCache: false,
          button: []
        }
      }
    ],
    sorts: -1
  },
  // {
  //   path: '/resume',
  //   component: Layout,
  //   hidden: false,
  //   alwaysShow: false,
  //   redirect: '/resume',
  //   meta: {},
  //   props: {
  //     showAside: true
  //   },
  //   children: [
  //     {
  //       path: '/resume',
  //       component: () => import('@/views/resume/list'),
  //       name: 'ResumeList',
  //       hidden: false,
  //       alwaysShow: false,
  //       meta: {
  //         title: '招聘数据统计',
  //         icon: 'project',
  //         noCache: false,
  //         button: []
  //       }
  //     }
  //   ]
  // },
  {
    path: '/404',
    component: () => import('@/views/error/404'),
    hidden: true
  },
  {
    path: '/403',
    component: () => import('@/views/error/403'),
    hidden: true
  }
]

if (!isJoinUUMS) {
  routes.push({
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/index'),
    hidden: true,
    meta: {
      title: '登录'
    }
  })
}

if (process.env.NODE_ENV === 'development') {
  routes.push({
    path: '/components',
    component: () => import('@/views/components/index'),
    hidden: true,
    meta: {
      title: '组件'
    }
  })
  routes.push({
    path: '/menus',
    component: () => import('@/views/system/menu/list'),
    hidden: true
  })
}

const createRouter = () => new VueRouter({
  scrollBehavior: (to, from) => ({
    y: to.meta?.scrollTop || 0
  }),
  routes
})

const router = createRouter()

/**
 * 重置路由
 * @return {void}
 * @Author: Ten
 * @Date: 2021/10/06 12:45
 */
router.resetRouter = function() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher
}

export default router
