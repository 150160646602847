/*
 * @Author: Ten
 * @Date: 2021/08/31 17:34
 * @LastEditTime: 2021/09/16 17:20
 * @LastEditors: Ten
 * @Description: 项目管理
 */
import request from '@/api/base/request'

export function createProject(data) { // 创建接口
  return request({
    url: '/project',
    method: 'post',
    data
  })
}

export function readProject(id) { // 详情接口
  return request({
    url: `/project/${id}`,
    method: 'get'
  })
}

export function readProjects(params) { // 列表接口（分页）
  return request({
    url: '/project',
    method: 'get',
    params
  })
}

export function readAllProjects(params) { // 列表接口（不分页）
  return request({
    url: '/projects',
    method: 'get',
    params
  })
}

export function updateProject(id, data) { // 更新接口
  return request({
    url: `/project/${id}`,
    method: 'put',
    data
  })
}

export function modifyProjectSecret(id, data) { // 刷新secret接口
  return request({
    url: `/project-secret/${id}`,
    method: 'put',
    data
  })
}

export function delProject(id) { // 删除接口
  return request({
    url: `/project/${id}`,
    method: 'delete'
  })
}

export function getProjectMoney() { // 货币列表接口
  return request({
    url: 'project-money',
    method: 'get'
  })
}
