/*
 * @Author: Ten
 * @Date: 2021/10/26 12:09
 * @LastEditTime: 2022/02/28 16:40
 * @LastEditors: Ten
 * @Description:
 */
export const routerMap = {
  'system/user/list': {
    create: {
      name: 'SystemUserCreate',
      title: '添加新成员',
      component: 'system/user/create'
    },
    detail: {
      name: 'SystemUserCreate',
      title: '编辑成员',
      component: 'system/user/create'
    }
  },
  'system/role/list': {
    create: {
      name: 'SystemRoleCreate',
      title: '添加角色',
      component: 'system/role/create'
    },
    detail: {
      name: 'SystemRoleCreate',
      title: '编辑角色',
      component: 'system/role/create'
    }
  },
  'project/user/list': {
    create: {
      name: 'ProjectUserCreate',
      title: '添加新成员',
      component: 'project/user/create'
    },
    detail: {
      name: 'ProjectUserCreate',
      title: '编辑成员',
      component: 'project/user/create'
    }
  },
  'project/role/list': {
    create: {
      name: 'ProjectRoleCreate',
      title: '添加新角色',
      component: 'project/role/create'
    },
    detail: {
      name: 'ProjectRoleCreate',
      title: '编辑角色',
      component: 'project/role/create'
    }
  }
}
