/**
 * 登录模块
 */
import request from '@/api/base/request'

export function login(data) { // 登录接口
  return request({
    url: '/login',
    method: 'post',
    data
  })
}

export function wwLogin(params) { // 企业微信扫码登录接口
  return request({
    url: '/wechat-login',
    method: 'get',
    params
  })
}

export function getUserInfo() { // 用户权限接口
  return request({
    url: '/user-info',
    method: 'get'
  })
}

export function getCaptcha() { // 图片验证码接口
  return request({
    url: '/captcha',
    method: 'get'
  })
}

export function authToken(params) { // 令牌校验接口
  return request({
    url: '/auth-token',
    params
  })
}
