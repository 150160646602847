<template>
  <div>
    <el-checkbox-group
      class="project-checkbox-group"
      v-model="currentValue"
      @change="onChange"
    >
      <el-checkbox
        v-for="item in projects"
        :key="item.id"
        :label="item.id"
        size="medium"
        border
      >
        {{ item.title }}
      </el-checkbox>
    </el-checkbox-group>
    <div v-if="projects.length === 0" class="project-checkbox-group__empty">暂无数据</div>
  </div>
</template>

<script>
import { readAllProjects } from '@/api/base/modules/project'
export default {
  name: 'ProjectCheckboxGroup',
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      projects: [],
      currentValue: []
    }
  },
  watch: {
    value(val) {
      this.currentValue = (val.split(',') || []).map(item => Number(item))
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      return new Promise((resolve, reject) => {
        readAllProjects().then((res) => {
          resolve()
          this.projects = res.data || []
        }).catch(err => {
          reject(err)
        })
      })
    },
    onChange(val) {
      this.$emit('input', val.join(','))
    }
  }
}
</script>

<style lang="scss" scoped>
.project-checkbox-group {
  ::v-deep {
    .el-form-item {
      margin-bottom: 10px;
    }
    .el-checkbox-group {
      padding-left: 5px;
      padding-right: 5px;
    }
    .el-checkbox {
      padding: 4px 8px;
      margin-right: 5px;
      margin-bottom: 10px;
      height: auto;
      .el-checkbox__label {
        padding-left: 5px;
        word-break: break-word;
        white-space: normal;
        vertical-align: middle;
        font-size: 12px;
        font-weight: normal;
      }
    }
    .el-checkbox.is-bordered + .el-checkbox.is-bordered {
      margin-left: 0;
    }
  }
}
.project-checkbox-group__empty {
  margin-top: -5px;
  color: $color--empty;
}
</style>
