<!--
 * @Author: Qy
 * @Date: 2021/10/15 15:32
 * @LastEditTime: 2021/10/16 10:29
 * @LastEditors: Qy
 * @Description:header nav-bg navbar__item--collapse
-->
<template>
  <header class="navbar ">
    <div class="navbar__item" >
      <Logo />
    </div>
  </header>
</template>

<script>
import Logo from '@client/layout/comps/Logo.vue'

export default {
  name: 'Navbar',
  components: {
    Logo
  },
  props: {},
  data() {
    return {}
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
.navbar {
  display: flex;
  justify-content: space-between;
  position: relative;
  height:69px;
  background-color:#181818;
  color: #fff;
  overflow: hidden;
  .navbar__item {
    display: flex;
    align-items: center;
    height: 100%;
    line-height: 50px;
    &:focus {
      outline: none;
    }

    .navbar__inner {
      display: flex;
      padding-left: 20px;
      padding-right: 20px;
      font-size: 16px;
      color: #fff;
      vertical-align: text-bottom;
      transition: background-color 0.3s;
      &:hover {
        cursor: pointer;
      }
    }

    .navbar__name {
      margin-left: 8px;
      font-size: 18px;
      color: #333;
    }
  }
}
// .header {
//   position: fixed;
//   right: 0;
//   left: 0;
//   padding: 0 160px 0 40px;
//   right: 5px;
//   top: 0;
//   z-index: 1500;
//   width: 100%;
//   height: 69px;
//   line-height: 69px;
//   &.nav-bg {
//     background-color: #181818;
//   }
// }
</style>
