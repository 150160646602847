/*
 * @Author: Qy
 * @Date: 2021/10/15 14:59
 * @LastEditTime: 2021/10/15 15:20
 * @LastEditors: Qy
 * @Description:外层框架
 */
<template>
  <div class="app-wrapper">
    <div class="fixed-header" v-if="device !== 'mobile'">
      <Navbar />
    </div>
    <div class="main-container-page">
      <div class="main-container">
        <div :class="[device !== 'mobile' ? 'is-fixed-header' : '']"></div>
        <Main />
        <Footer />
      </div>
    </div>
    <el-backtop :bottom="20" :right="20">
      <div class="backtop-ico">
        <i class="ico el-icon-arrow-up"></i>
      </div>
    </el-backtop>
  </div>
</template>

<script>
import Main from '@client/layout/comps/Main.vue'
import Navbar from '@client/layout/comps/Navbar.vue'
import Footer from '@client/layout/comps/Footer.vue'
import ResizeMixin from './mixins/ResizeHandler'
import { mapState } from 'vuex'

export default {
  name: 'Layout',
  mixins: [ResizeMixin],
  components: {
    Main,
    Navbar,
    Footer
  },
  computed: {
    ...mapState({
      device: (state) => state.app.device
    })
  },
  data() {
    return {}
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
.app-wrapper {
  height: 100%;
  width: 100%;
}
.main-container-page {
  height: 100%;
  background-color: #f3f5f7;
}
.main-container{
  display: flex;
    min-height: 100vh;
    flex-direction: column;
}
.fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: width 0.28s;
  z-index: 2000;
}
.is-fixed-header {
  height: 69px;
}
.aside__mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999;
}
.backtop-ico {
  display: flex;
  width: 40px;
  height: 40px;
  background-color: #3481FC;
  text-align: center;
  border-radius: 40px;
  justify-content: center;
  align-items: center;
  .ico {
    font-size: 24px;
    color: #fff;
  }
}
</style>
