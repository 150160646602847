<template>
  <div :class="classObj" class="app-wrapper">
    <!-- <div
      v-if="device === 'mobile' && aside.opened"
      class="aside__mask"
      @click="handleCloseAside"
    /> -->
    <div :class="{ 'fixed-header': fixedHeader }">
      <Navbar :showHamburger="false" />
      <TagsView v-if="needTagsView" class="tags-view-container" />
    </div>
    <div class="h-100p">
      <div v-show="showAside" class="aside-container">
        <ProjectGlobalSelector v-show="this.aside.opened" />
        <Aside />
      </div>
      <div class="main-container">
        <div v-show="fixedHeader" :style="{ height: needTagsView ? '101px' : '64px'}"></div>
        <Breadcrumb v-show="showBreadcrumb" class="breadcrumb-container" />
        <!-- <PageHeader /> -->
        <Main />
      </div>
    </div>
  </div>
</template>

<script>
import Aside from './comps/Aside/index.vue'
import Main from './comps/Main/index.vue'
import Navbar from './comps/Navbar/index.vue'
import TagsView from './comps/TagsView/index.vue'
// import PageHeader from './comps/PageHeader/index.vue'
import Breadcrumb from './comps/Breadcrumb/index.vue'
import ResizeMixin from './mixins/ResizeHandler'
import { ProjectGlobalSelector } from '@/components/project'
import { mapState } from 'vuex'

export default {
  name: 'Layout',
  mixins: [ResizeMixin],
  components: {
    Aside,
    Main,
    Navbar,
    TagsView,
    // PageHeader,
    Breadcrumb,
    ProjectGlobalSelector
  },
  props: {
    showAside: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState({
      aside: (state) => state.app.aside,
      // device: (state) => state.app.device,
      showSettings: (state) => state.settings.showSettings,
      needTagsView: (state) => state.settings.tagsView,
      fixedHeader: (state) => state.settings.fixedHeader
    }),
    classObj({ aside, showAside }) {
      return {
        'aside--close': !aside.opened,
        'aside--open': aside.opened,
        'aside--hidden': !showAside,
        'without-animation': aside.withoutAnimation
        // mobile: device === 'mobile'
      }
    },
    showBreadcrumb() {
      // return this.$route.name !== 'ResumeList' || this.$route.name !== 'SalaryList'
      return false
    }
  },
  methods: {
    handleCloseAside() {
      this.$store.dispatch('app/closeAside', { withoutAnimation: false })
    },
    onMenuAutocompleteFold() {
      this.$store.dispatch('app/toggleAside')
    }
  }
}
</script>

<style lang="scss" scoped>
.app-wrapper {
  width: 100%;
  height: 100%;
}

.tags-view-container {
  margin-left: $aside-width;
  width: calc(100% - #{$aside-width});
  transition: all .28s;
  overflow: hidden;
}

.breadcrumb-container {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 24px;
  height: 32px;
}

.main-container {
  display: flex;
  flex-direction: column;
  margin-left: $aside-width;
  padding: 0 24px 24px;
  min-height: 100%;
  transition: margin-left .28s;
  background-color: $bg-color--base;
}

.aside-container {
  position: fixed;
  top: $navbar-height;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: $aside-width !important;
  height: calc(100% - #{$navbar-height});
  box-shadow: 0 5px 20px 0 rgba(176, 195, 211, 0.16);;
  background-color: $menu-bg-color;
  font-size: 0;
  overflow: hidden;
  transition: width 0.28s;
  z-index: 2000;
  ::v-deep {
    // reset element-ui css
    // .horizontal-collapse-transition {
    //   transition: 0s width ease-in-out, 0s padding-left ease-in-out, 0s padding-right ease-in-out;
    // }
    .scrollbar-wrapper {
      overflow-x: hidden !important;
    }
    .el-scrollbar__bar.is-vertical {
      right: 0px;
    }
    .el-scrollbar {
      height: 100%;
    }
    .is-horizontal {
      display: none;
    }
    a {
      display: inline-block;
      width: 100%;
      overflow: hidden;
    }
    .sub-el-icon {
      margin-right: 12px;
      margin-left: -2px;
    }
    .el-menu {
      height: 100%;
      width: 100% !important;
      border: none;
      .svg-icon {
        margin-right: 12px;
        font-size: 16px;
        color: #8C94A5;
      }
    }
    .el-submenu {
      &.is-active {
        .el-submenu__title {
          background-color: $menu-bg-color--active !important;
          color: $menu-text-color--active !important;
          font-weight: 500;
        }
        .el-menu-item {
          background-color: $menu-bg-color !important;
        }
        .svg-icon {
          color: $color--primary;
        }
      }
      .el-menu-item {
        padding-left: 40px !important;
        padding-right: 40px !important;
      }
      .el-menu {
        .el-menu-item {
          .svg-icon {
            display: none;
          }
        }
      }
    }
    .el-menu-item {
      position: relative;
      margin-top: 2px;
      margin-bottom: 2px;
      height: 40px;
      line-height: 40px;
      border-radius: 4px;
      &:hover {
        background-color: $menu-bg-color !important;
        color: $menu-text-color--active !important;
        .svg-icon {
          color: $menu-text-color--active !important;
        }
      }
      &.is-active {
        background-color: $menu-bg-color--active !important;
        color: $menu-text-color--active !important;
        font-weight: bold;
        .svg-icon {
          color: $color--primary;
        }
      }
    }
    .el-submenu__title {
      line-height: 40px;
      height: 40px;
      border-radius: 4px;
      &:hover {
        background-color: $menu-bg-color !important;
        color: $menu-text-color--active !important;
        .svg-icon {
          color: $color--primary;
        }
      }
    }
    .el-submenu__title,
    .el-menu-item {
      padding-left: 12px!important;
      padding-right: 12px!important;
    }
    .el-submenu__icon-arrow {
      top: 0;
      right: 12px;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      height: 12px;
      font-size: 16px;
      color: $color-text--secondary;
    }
  }
}

.aside__mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .3);
  z-index: 999;
}

.fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: width 0.28s;
  z-index: 2000;
}
</style>

<style lang="scss" scoped>
.aside--close {
  .aside-container {
    width: $aside-width--mini !important;
    ::v-deep {
      .aside {
        padding-left: 4px;
        padding-right: 4px;
      }
      .el-submenu > .el-submenu__title .svg-icon {
        margin: 0;
      }
      .el-menu-item > .el-tooltip {
        position: relative !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
  }

  .tags-view-container {
    margin-left: $aside-width--mini;
    width: calc(100% - #{$aside-width--mini});
  }

  .main-container {
    margin-left: $aside-width--mini;
  }

  .submenu-title-no-dropdown {
    position: relative;
    padding: 0 !important;
    ::v-deep {
      .el-tooltip {
        padding: 0 !important;
        .svg-icon {
          margin-left: 20px;
        }
        .sub-el-icon {
          margin-left: 19px;
        }
      }
    }
  }

  ::v-deep {
    .el-submenu {
      overflow: hidden;

      &>.el-submenu__title {
        .svg-icon {
          margin-left: 20px;
        }
        .sub-el-icon {
          margin-left: 19px;
        }
        .el-submenu__icon-arrow {
          display: none;
        }
      }
    }

    .el-menu--collapse {
      .el-submenu {
        &>.el-submenu__title {
          &>span {
            height: 0;
            width: 0;
            overflow: hidden;
            visibility: hidden;
            display: inline-block;
          }
        }
      }
    }
  }
}

.without-animation {
  .main-container,
  .aside-container,
  .fixed-header {
    transition: none;
  }
}
.aside--hidden {
  .main-container {
    margin-left: 0;
  }
  .fixed-header {
    width: 100%;
  }
}
</style>

<style lang="scss" scoped>
// mobile responsive
.mobile {
  &.app-wrapper {
    &.aside--open {
      position: fixed;
      top: 0;
    }
  }
  .main-container {
    margin-left: 0px;
  }
  .aside-container {
    transition: transform .28s;
    width: $aside-width !important;
  }
  &.aside--close {
    .aside-container {
      pointer-events: none;
      transition-duration: 0.3s;
      transform: translate3d(-$aside-width, 0, 0);
    }
  }
  .fixed-header {
    width: 100%;
    transition: none;
  }
  .tags-view-container {
    margin-left: 0;
    width: 100%;
  }
}
</style>

<style lang="scss">
// when menu collapsed
.el-menu--vertical {
  &>.el-menu {
    .svg-icon {
      margin-right: 16px;
    }
    .sub-el-icon {
      margin-right: 12px;
      margin-left: -2px;
    }
  }

  // the scroll bar appears when the subMenu is too long
  >.el-menu--popup {
    max-height: 100vh;
    overflow-y: auto;

    &::-webkit-scrollbar-track-piece {
      background: #d3dce6;
    }

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: #99a9bf;
      border-radius: 20px;
    }
  }
}
.el-menu--collapse .el-menu .el-submenu {
  min-width: $aside-width !important;
}
</style>
