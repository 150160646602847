<template>
  <div class="flex flex-column h-100p">
    <el-scrollbar wrap-class="scrollbar-wrapper flex-1">
      <el-menu
        :default-active="activeMenu"
        :collapse="isCollapse"
        :unique-opened="true"
        :collapse-transition="false"
        :background-color="variables.menuBgColor"
        :text-color="variables.menuTextColor"
        :active-text-color="variables.menuTextColorActive"
        mode="vertical"
        class="aside"
      >
        <AsideItem
          v-for="route in formatRoutes"
          :key="route.name"
          :item="route"
          :base-path="route.path"
        />
      </el-menu>
    </el-scrollbar>
    <div class="aside-links">
      <Hamburger
        :is-active="aside.opened"
        class="hamburger-container"
        @toggleClick="handleToggleAside"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AsideItem from './comps/AsideItem/index.vue'
import Hamburger from '../Navbar/comps/Hamburger.vue'
import variables from '@/styles/variables.scss'
import { orderBy } from 'lodash'

export default {
  name: 'Aside',
  components: {
    AsideItem,
    Hamburger
  },
  computed: {
    ...mapGetters({
      permissionsRoutes: 'permissions/routes',
      aside: 'app/aside'
    }),
    formatRoutes({ permissionsRoutes }) {
      return orderBy(permissionsRoutes, ['sorts'], ['desc'])
    },
    activeMenu() {
      const { meta, path, name } = this.$route
      // if set path, the aside will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return name || path
    },
    showLogo() {
      return this.$store.state.settings.showLogo
    },
    variables() {
      return variables
    },
    isCollapse({ aside }) {
      return !aside.opened
    }
  },
  methods: {
    handleToggleAside() {
      this.$store.dispatch('app/toggleAside')
    }
  }
}
</script>

<style lang="scss" scoped>
.aside {
  padding: 12px 16px 24px;
}
.aside-links {
  padding: 10px 12px;
  width: 100%;
  border-top: 1px solid #EDF1F7;
}
.hamburger-container {
  margin-left: 3px;
  width: 18px;
  height: 18px;
  transition: background-color 0.3s;
}
</style>
