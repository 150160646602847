/*
 * @Author: Ten
 * @Date: 2021/10/11 00:45
 * @LastEditTime: 2022/02/23 20:09
 * @LastEditors: Ten
 * @Description: 请求相关工具方法
 */
import { isFunction } from '@/utils/is'

/**
 * 加载请求拦截器
 * @param {object} instance axios 实例
 * @param {object} interceptors 拦截器对象
 * @param {object} options
 * @return {void}
 * @Author: Ten
 * @Date: 2021/10/11 15:17
 */
export function loadRequestInterceptors(instance, interceptors, options) {
  const { requestInterceptors, responseInterceptors } = interceptors
  // 加载请求拦截器
  requestInterceptors.reverse().forEach(interceptor => {
    let { onResolved, onRejected } = interceptor
    if (!isFunction(onResolved)) {
      onResolved = config => config
    }
    if (!isFunction(onRejected)) {
      onRejected = error => Promise.reject(error)
    }
    instance.interceptors.request.use(
      config => onResolved(config, options),
      error => onRejected(error, options)
    )
  })
  // 加载响应拦截器
  responseInterceptors.forEach(interceptor => {
    let { onResolved, onRejected } = interceptor
    if (!isFunction(onResolved)) {
      onResolved = response => response
    }
    if (!isFunction(onRejected)) {
      onRejected = error => Promise.reject(error)
    }
    instance.interceptors.response.use(
      response => onResolved(response, options),
      error => onRejected(error, options, instance)
    )
  })
}
