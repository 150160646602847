<!--
 * @Author: Qy
 * @Date: 2021/10/15 15:32
 * @LastEditTime: 2022/03/15 16:36
 * @LastEditors: Qy
 * @Description:
-->
<template>
  <footer class="footer">
    Indrasoft版权所有
    <el-link
      href="https://beian.miit.gov.cn/#/Integrated/index"
      target="_blank"
      type="primary"
      >@2013-2022粤ICP备15003077号</el-link
    >
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style lang="scss" scoped>
.footer {
  padding: 20px;
  text-align: center;
  font-size: 14px;
  color: #8C94A5;
  background-color: #f3f5f7;
  margin-top: auto;
}
</style>
