import { routes as localRoutes } from '@/router'
import {
  formatRoutes,
  loadRoutes,
  flattenRoutes,
  initActiveMenuRoutes,
  initRedirectRoutes,
  // initPageTitleRoutes,
  initHomeRoute,
  initErrorRoute,
  generateAuthRoutes
} from '@/utils/router'
import { readConfigPermission } from '@/api/base/modules/config-permission'

const generateRoutes = function(routes, routesMap) {
  return loadRoutes(
    initErrorRoute(
      initHomeRoute(
        initRedirectRoutes(
          flattenRoutes(
            initActiveMenuRoutes(
              // initPageTitleRoutes(
              initRedirectRoutes(routes)
              // )
            )
          )
        )
      ), routesMap
    )
  )
}

const state = {
  routes: [],
  asyncRoutes: [],
  buttonMap: [],
  routesMap: []
}

const getters = {
  routes: ({ routes }) => routes,
  buttonMap: ({ buttonMap }) => buttonMap,
  routesMap: ({ routesMap }) => routesMap
}

const mutations = {
  setRoutes: (state, routes) => {
    state.asyncRoutes = routes
    state.routes = localRoutes.concat(routes)
  },
  setButtonMap: (state, buttonMap) => {
    state.buttonMap = buttonMap
  },
  setRoutesMap: (state, routesMap) => {
    state.routesMap = routesMap
  }
}

const actions = {
  generateRoutes({ commit, state }, data) {
    return new Promise(resolve => {
      const routes = formatRoutes(generateAuthRoutes(data))
      commit('setRoutes', routes)
      resolve(generateRoutes(routes, state.routesMap))
    })
  },
  getConfigPermission({ commit }) {
    return new Promise((resolve, reject) => {
      readConfigPermission().then(res => {
        let { button_config: buttonMap, front_end_route_path: routesMap } = res.data
        buttonMap = buttonMap.map(item => {
          return {
            id: item.id,
            pid: item.pid,
            value: item.id,
            map: item.map,
            label: item.name
          }
        })
        commit('setButtonMap', buttonMap)
        commit('setRoutesMap', routesMap)
        resolve({
          routesMap,
          buttonMap
        })
      }, err => {
        reject(err)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
