/*
 * @Author: Ten
 * @Date: 2021/10/03 10:52
 * @LastEditTime: 2021/10/03 10:53
 * @LastEditors: Ten
 * @Description: Storage
 */
export function getItem(key) {
  return localStorage.getItem(key)
}

export function setItem(key, value) {
  return localStorage.setItem(key, value)
}

export function removeItem(key) {
  return localStorage.removeItem(key)
}

export function clear() {
  return localStorage.clear()
}
