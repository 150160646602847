/*
 * @Author: Ten
 * @Date: 2021/08/31 20:47
 * @LastEditTime: 2022/03/01 11:28
 * @LastEditors: Ten
 * @Description:
 */
import { readAllRoles } from '@/api/base/modules/role'

const state = {
  data: []
}

const getters = {
  data: (state) => state.data
}

const mutations = {
  setData: (state, roles) => {
    state.data = roles
  }
}

const actions = {
  getData({ commit }, params) {
    return new Promise((resolve, reject) => {
      readAllRoles(params).then((res) => {
        const data = res.data || []
        commit('setData', data)
        resolve(data)
      }).catch(err => {
        console.log(err)
        reject(err)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
