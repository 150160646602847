import { login, wwLogin, getUserInfo, authToken } from '@/api/base/modules/auth'
import { getToken, setToken, removeToken } from '@/utils/token'
import { defaultAvatar } from '@/settings'
import router from '@/router'

const state = {
  token: getToken(),
  id: 0,
  name: '',
  avatar: '',
  status: '',
  realname: '',
  nickname: '',
  phone: '',
  projectId: Number(localStorage.getItem('projectId') || 0) || '',
  projectKey: ''
}

const getters = {
  id: (state) => state.id,
  name: (state) => state.name,
  nickname: (state) => state.nickname,
  realname: (state) => state.realname,
  avatar: (state) => state.avatar || defaultAvatar,
  phone: (state) => state.phone,
  token: ({ token }) => token,
  // access: ({ access }) => access,
  projectId: ({ projectId }) => projectId || '',
  projectKey: ({ projectKey }) => projectKey || ''
}

const mutations = {
  setToken: (state, token) => {
    state.token = token
    if (token) {
      setToken(token)
    } else {
      removeToken()
    }
  },
  setId: (state, id) => {
    state.id = id
  },
  setName: (state, name) => {
    state.name = name
  },
  setAvatar: (state, avatar) => {
    state.avatar = avatar
  },
  setRealname: (state, realname) => {
    state.realname = realname
  },
  setNickname: (state, nickname) => {
    state.nickname = nickname
  },
  setPhone: (state, phone) => {
    state.phone = phone
  },
  setStatus: (state, status) => {
    state.status = status
  },
  setProjectId: (state, projectId) => {
    state.projectId = projectId
    localStorage.setItem('projectId', projectId)
  },
  setProjectKey: (state, projectKey) => {
    state.projectKey = projectKey
  }
}

const actions = {
  login({ commit }, userInfo) { // 账号密码登录
    const { username, password, code_key: codeKey, code } = userInfo
    return new Promise((resolve, reject) => {
      login({
        username: username.trim(),
        password,
        code_key: codeKey,
        code
      }).then(res => {
        const { user_token: userToken } = res.data
        commit('setToken', userToken)
        resolve()
      }).catch(err => {
        reject(err)
      })
    })
  },
  wwLogin({ commit }, query) { // 企业微信扫码登录
    const { appid, code, state } = query
    return new Promise((resolve, reject) => {
      wwLogin({
        appid,
        code,
        state
      }).then(res => {
        const { user_token: userToken } = res.data
        commit('setToken', userToken)
        resolve()
      }).catch(err => {
        reject(err)
      })
    })
  },
  logout({ dispatch }, pathname) { // 退出登录
    dispatch('removeToken')
    router.replace({
      path: '/login',
      query: {
        pathname
      }
    })
  },
  getUser({ commit }) { // 获取用户信息
    return new Promise((resolve, reject) => {
      getUserInfo().then(res => {
        const { data } = res
        const {
          user_info: userInfo,
          projects
        } = data
        commit('setId', userInfo.id)
        commit('setName', userInfo.username)
        commit('setAvatar', userInfo.image_path)
        commit('setRealname', userInfo.real_name)
        commit('setNickname', userInfo.nick_name)
        commit('setPhone', userInfo.phone)
        resolve({
          projects
        })
      }).catch(err => {
        reject(err)
      })
    })
  },
  removeToken({ commit, dispatch }) {
    commit('setToken', '')
    commit('setId', 0)
    removeToken()
    router.resetRouter()
    // reset visited views and cached views
    // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
    dispatch('tagsView/delAllViews', null, { root: true })
  },
  authToken({ commit }, token) { // 令牌校验
    return new Promise((resolve, reject) => {
      authToken({
        user_token: token
      }).then(res => {
        commit('setToken', res.data)
        resolve()
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
