/*
 * @Author: Ten
 * @Date: 2021/08/09 10:38
 * @LastEditTime: 2022/05/14 10:43
 * @LastEditors: Qy
 * @Description: 字符串方法
 */
/**
 * 创建随机字符串
 * @param {number} len 字符串长度
 * @param {string} charSet 特征字符集合
 * @return {string}
 * @Author: Ten
 * @Date: 2021/10/11 16:13
 */
export function randomString(len = 16, charSet) {
  charSet = charSet || 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let randomString = ''
  for (let i = 0; i < len; i++) {
    const randomPoz = Math.floor(Math.random() * charSet.length)
    randomString += charSet.substring(randomPoz, randomPoz + 1)
  }
  return randomString
}

/**
 * 过滤img标签
 * @param {string} value HTML标签字符串
 * @return {string}
 * @Author: Ten
 * @Date: 2021/10/11 16:15
 */
export function filterImgTags(value) {
  return value.replace(/<img.*?(?:>|\/>)/gi, '')
}

/**
 * 去除所有空格
 * @param {string} value 字符串
 * @return {string}
 * @Author: Ten
 * @Date: 2021/10/11 16:16
 */
export function trimAll(value) {
  return value.replace(/\s+/g, '')
}

/**
 * 获取字符长度
 * @param {string} str 字符串
 * @return {number} 字符长度
 * @Author: Ten
 * @Date: 2021/10/08 17:52
 */
export function getCharLength(str) {
  // eslint-disable-next-line no-control-regex
  const r = /[^\x00-\xff]/g
  return str.replace(r, 'mm').length
}

/**
 * 通过字符长度截取字符串
 * @param {string} str 字符串
 * @param {number} len 截取长度
 * @return {string}
 * @Author: Ten
 * @Date: 2021/10/08 17:49
 */
export function cutStrByChar(str, len) {
  let result = ''
  const strLen = str.length // 字符串长度
  // eslint-disable-next-line no-control-regex
  const charLen = str.replace(/[^\x00-\xff]/g, '**').length // 字节长度
  if (charLen <= len) {
    return str
  }
  for (let i = 0, j = 0; i < strLen; i++) {
    const char = str.charAt(i)
    // eslint-disable-next-line no-control-regex
    if (/[\x00-\xff]/.test(char)) {
      j++ // ascii码为0-255，一个字符就是一个字节的长度
    } else {
      j += 2 // ascii码为0-255以外，一个字符就是两个字节的长度
    }
    if (j <= len) { // 当加上当前字符以后，如果总字节长度小于等于L，则将当前字符真实的+在result后
      result += char
    } else { // 反之则说明result已经是不拆分字符的情况下最接近L的值了，直接返回
      return result
    }
  }
}

// 数字金额过滤器，保留两位，显示千分位符号
export function formatMoney(value) {
  if (!value) return '0.00'
  // 将数值截取，保留两位小数
  value = value.toFixed(2)
  // 获取整数部分
  const intPart = Math.trunc(value)
  // 整数部分处理，增加,
  const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
  // 预定义小数部分
  let floatPart = '.00'
  // 将数值截取为小数部分和整数部分
  const valueArray = value.toString().split('.')
  if (valueArray.length === 2) { // 有小数部分
    floatPart = valueArray[1].toString() // 取得小数部分
    return intPartFormat + '.' + floatPart
  }
  return intPartFormat + floatPart
}
