<template>
  <el-select
    :loading="loading"
    v-model="currentValue"
    placeholder="请选择"
    :disabled="disabled"
    filterable
    :clearable="clearable"
    @change="onChange"
  >
    <el-option
      v-for="item in options"
      :key="item[valueKey]"
      :label="item.title"
      :value="item[valueKey]"
    />
  </el-select>
</template>

<script>
export default {
  name: 'ProjectSelector',
  props: {
    value: {
      type: [Number, String],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    valueKey: {
      type: String,
      default: 'id'
    }
  },
  data() {
    return {
      loading: false,
      currentValue: ''
    }
  },
  computed: {
    options() {
      return this.$store.getters['project/data']
    }
  },
  watch: {
    value: {
      handler(val) {
        this.currentValue = val
      },
      immediate: true
    }
  },
  methods: {
    onChange(val) {
      const option = this.options.find(opt => opt.key === val)
      this.$emit('input', val, option)
      this.$emit('change', val, option)
    }
  }
}
</script>
