/*
 * @Author: Ten
 * @Date: 2021/09/02 20:41
 * @LastEditTime: 2021/09/08 15:18
 * @LastEditors: Ten
 * @Description:
 */
import { readAllProjects } from '@/api/base/modules/project'

const state = {
  data: []
}

const getters = {
  data: (state) => state.data
}

const mutations = {
  setData: (state, data) => {
    state.data = data
  }
}

const actions = {
  getData({ commit, state }) {
    if (state.data && state.data.length > 0) {
      return Promise.resolve(state.data)
    }
    return new Promise((resolve, reject) => {
      readAllProjects().then((res) => {
        const data = res.data || []
        commit('setData', data)
        resolve(data)
      }).catch(err => {
        console.log(err)
        reject(err)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
