import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@client/layout/index.vue'

Vue.use(Router)

export const constantRoutes = [
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/',
        title: 'Indra应聘登记表',
        component: () => import('@client/views/job/index')
      }
      // ,
      // {
      //   path: '/test',
      //   title: '测试',
      //   component: () => import('@client/views/job/test-time')
      // }
    ]
  },
  {
    path: '/404',
    component: () => import('@client/views/error/404')
  },
  {
    path: '/403',
    component: () => import('@client/views/error/403')
  }
]

const createRouter = () => new Router({
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

export default router
