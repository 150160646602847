<template>
  <div class="hamburger" @click="toggleClick">
    <i :class="[className, 'hamburger']"></i>
  </div>
</template>

<script>
export default {
  name: 'Hamburger',
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    className() {
      return this.isActive ? 'el-icon-s-fold' : 'el-icon-s-unfold'
    }
  },
  methods: {
    toggleClick() {
      this.$emit('toggleClick')
    }
  }
}
</script>

<style lang="scss" scoped>
.hamburger {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  font-size: 16px;
  color: $hamburger-text-color;
  &:hover {
    cursor: pointer;
    color: $hamburger-text-color--active;
  }
  .humburger__icon {
    vertical-align: -0.16em;
  }
}
</style>
